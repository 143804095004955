import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationRenderResumeArgs } from '../../generated/types';

export const RENDER_RESUME = gql`
  mutation RenderResume(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String
    $resumeUuid: String
    $state: JSON
  ) {
    renderResume(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      resumeUuid: $resumeUuid
      state: $state
    )
  }
`;

export const useRenderResume = (
  options?: MutationHookOptions<Pick<Mutation, 'renderResume'>, MutationRenderResumeArgs>
) =>
  useMutation<Pick<Mutation, 'renderResume'>, MutationRenderResumeArgs>(RENDER_RESUME, {
    ...options,
  });
