import { Link } from '@styled-icons/fa-solid';
import { LinkSlash } from '@styled-icons/fa-solid';

import { Icon, IconProps } from './Icon';

export function LinkIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Link />
    </Icon>
  );
}

export function LinkSlashIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <LinkSlash />
    </Icon>
  );
}
