import { ArrowGoBack } from '@styled-icons/remix-line';
import { ArrowGoBack as ArrowGoBackFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function UndoIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ArrowGoBack />
    </Icon>
  );
}

export function UndoFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <ArrowGoBackFill />
    </Icon>
  );
}
